<template>
  <div>
    <div class="customTitle">
      <i class="fa fa-home"></i>
      <span style="margin-left: 10px">主页</span>
      <i class="fa fa-angle-right" style="margin-left: 10px"></i>
      <span style="margin-left: 10px">定制服务</span>
    </div>
    <!-- 定制服务部分 -->
    <div class="customService">
      <h3 style="margin: 0 0 0 10px">
        定制服务
        <span style="float: right; font-weight: 300; margin: 0 10px 0 0"
          >更多>></span
        >
      </h3>
      <div style="border-bottom: 1px solid #dcdfe6; margin: 10px 0 0 0" />
      <el-row :gutter="20">
        <el-col
          :xs="24"
          :lg="6"
          :offset="0"
          v-for="item in about"
          :key="item.id"
          style="padding: 20px"
        >
          <el-popover
            placement="top-start"
            trigger="hover"
            :content="item.content"
            :disabled="item.hidePopover"
          >
            <el-row
              slot="reference"
              gutter="40"
              @click.native="handleClick(item.dialog)"
            >
              <el-col :xs="3" :lg="6">
                <img
                  :src="item.image"
                  alt="image"
                  style="width: 40px; height: 40px; border-radius: 100px"
                />
              </el-col>
              <el-col :xs="21" :lg="18" style="line-height: 15px">
                <p
                  style="
                    font-weight: bolder;
                    margin-top: 0px;
                    margin-bottom: 5px;
                  "
                >
                  {{ item.title }}
                  <i
                    class="fa fa-angle-right"
                    style="
                      float: right;
                      color: #abb0bd;
                      border: 1px #abb0bd solid;
                      padding: 0px 5px;
                      border-radius: 100px;
                    "
                  ></i>
                </p>
                <p class="customInfo">
                  {{ item.info }}
                </p>
              </el-col>
            </el-row>
          </el-popover>
        </el-col>
      </el-row>
    </div>
    <!-- 流程部分 -->
    <div class="customList">
      <h1>极速棋牌定制服务流程</h1>
      <p style="color: #666666">
        极速棋牌拥有多年行业经验，能够帮助客户快速部署，二次开发棋牌项目，有效帮助客户降低成本投入，不论有无经验，无障碍对接，极速实现客户所想。
      </p>
      <el-row :gutter="20" class="hide">
        <el-col :span="5" :offset="0">
          <i class="el-icon-s-order"></i>
        </el-col>
        <el-col :span="5" :offset="0">
          <i class="el-icon-edit"></i>
        </el-col>
        <el-col :span="5" :offset="0">
          <i class="el-icon-s-claim"></i>
        </el-col>
        <el-col :span="5" :offset="0">
          <i class="el-icon-s-platform"></i>
        </el-col>
        <el-col :span="5" :offset="0">
          <i class="el-icon-document-checked"></i>
        </el-col>
      </el-row>
      <el-row gutter="20" class="hide">
        <el-col span="5">
          <h3>需求分析</h3>
          <p>提交后，客服会主动联系你，了解基本需求</p>
        </el-col>
        <el-col span="5">
          <h3>方案设计</h3>
          <p>极速与客户沟通细节，并提供定制方案</p></el-col
        >
        <el-col span="5">
          <h3>开发调试</h3>
          <p>确定方案与费用后，极速签订合同</p></el-col
        >
        <el-col span="5">
          <h3>需求分析</h3>
          <p>极速根据方案内容开发调试安装产品、调试系统</p></el-col
        >
        <el-col span="5">
          <h3>项目验收</h3>
          <p>开发完成后，配合你现场验收，确保所有功能符合要求</p></el-col
        >
      </el-row>
      <el-row :gutter="20" class="phoneList">
        <el-col :span="24" :offset="0">
          <i class="el-icon-s-order phoneListi"></i>
          <h3>需求分析</h3>
          <p>提交后，客服会主动联系你，了解基本需求</p>
        </el-col>
        <el-col :span="24" :offset="0">
          <i class="el-icon-edit phoneListi"></i>
          <h3>方案设计</h3>
          <p>极速与客户沟通细节，并提供定制方案</p>
        </el-col>
        <el-col :span="24" :offset="0">
          <i class="el-icon-s-claim phoneListi"></i>
          <h3>开发调试</h3>
          <p>确定方案与费用后，极速签订合同</p>
        </el-col>
        <el-col :span="24" :offset="0">
          <i class="el-icon-s-platform phoneListi"></i>
          <h3>需求分析</h3>
          <p>极速根据方案内容开发调试安装产品、调试系统</p>
        </el-col>
        <el-col :span="24" :offset="0">
          <i class="el-icon-document-checked phoneListi"></i>
          <h3>项目验收</h3>
          <p>开发完成后，配合你现场验收，确保所有功能符合要求</p>
        </el-col>
      </el-row>
    </div>
    <!-- 游戏换皮服务弹窗 -->
    <el-dialog
      title="游戏换皮服务"
      :visible.sync="gameChangeDialog"
      width="30%"
    >
      <el-form>
        <el-form-item label="您的产品:">
          <el-select v-model="change.game" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <img :src="item.img" alt="" style="float: left; width: 30px" />
              <!-- <span style="float: left">{{ item.label }}</span> -->
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.label
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="换皮方式:">
          <el-radio-group v-model="change.type" size="small">
            <el-tooltip
              class="item"
              effect="dark"
              content="由UI按照您的想法对界面进行全新设计，价格相对较高"
              placement="top"
            >
              <el-radio label="1" border>全新设计</el-radio>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="模仿其他产品进行设计，性价比相对较高"
              placement="top"
            >
              <el-radio label="2" border>模仿产品</el-radio>
            </el-tooltip>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="您的联系方式(QQ或Telegram):">
          <el-input v-model="change.contact"></el-input>
        </el-form-item>
        <el-form-item label="您的需求描述:">
          <el-input
            v-model="change.info"
            type="textarea"
            :rows="4"
            placeholder="请输入您的具体需求"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="附件:">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="handleAvatarUpload"
            :http-request="handleCustomUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" center>
        <button type="primary" @click="gamechanges()">获取报价</button>
      </span>
    </el-dialog>
    <!-- 二次开发服务弹窗 -->
    <el-dialog
      title="二次开发服务"
      :visible.sync="secondChangeDialog"
      width="30%"
    >
      <el-form>
        <el-form-item label="您的产品:">
          <el-select v-model="second.game" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <img :src="item.img" alt="" style="float: left; width: 30px" />
              <!-- <span style="float: left">{{ item.label }}</span> -->
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.label
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="您的联系方式(QQ或Telegram):">
          <el-input v-model="second.contact"></el-input>
        </el-form-item>
        <el-form-item label="您的需求描述:">
          <el-input
            v-model="second.info"
            type="textarea"
            :rows="4"
            placeholder="请输入您的具体需求"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="附件:">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="handleAvatarUpload"
            :http-request="handleCustomUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" center>
        <button type="primary" @click="secondChanges()">获取报价</button>
      </span>
    </el-dialog>
    <!-- 个性化定制服务弹窗 -->
    <el-dialog title="个性化定制服务" :visible.sync="customDialog" width="30%">
      <p>如果您需要更多个性化定制服务，请与我们商务详谈</p>
      <div style="text-align: center">
        <a target="_blank" href="https://tool.gljlw.com/qq/?qq=3471043983">
          <img
            src="@/assets/QQ.png"
            alt=""
            style="
              width: 60px;
              height: 60px;
              background-color: white;
              border-radius: 100px;
              padding: 10px;
            "
          />
        </a>
        <a target="_blank" href="https://t.me/yliang103">
          <img
            src="@/assets/telegram.png"
            alt=""
            style="
              width: 60px;
              height: 60px;
              margin-top: 20px;
              margin-left: 50px;
            "
          />
        </a>
      </div>
    </el-dialog>
    <!-- 外包维护包月弹窗 -->
    <el-dialog title="外包维护包月服务" :visible.sync="fixedDialog" width="30%">
      <el-form>
        <el-form-item label="您的产品:">
          <el-select v-model="fixed.game" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <img :src="item.img" alt="" style="float: left; width: 30px" />
              <!-- <span style="float: left">{{ item.label }}</span> -->
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.label
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="技术要求">
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="前端"></el-checkbox>
            <el-checkbox label="后端"></el-checkbox>
            <el-checkbox label="美工"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="您的联系方式(QQ或Telegram):">
          <el-input v-model="fixed.contact"></el-input>
        </el-form-item>
        <el-form-item label="需求描述:">
          <el-input
            v-model="fixed.info"
            type="textarea"
            :rows="4"
            placeholder="请输入您的具体需求"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="附件:">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="handleAvatarUpload"
            :http-request="handleCustomUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" center>
        <button type="primary" @click="fixedGames()">获取报价</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { fixedGame, gamechange, secondChange } from "@/api/api";
export default {
  data() {
    return {
      change: [],
      second: [],
      fixed: [],
      checkList: [],
      gameChangeDialog: false,
      secondChangeDialog: false,
      customDialog: false,
      fixedDialog: false,
      about: [
        {
          id: 1,
          image: "https://www.freeimg.cn/i/2024/08/13/66ba5359152cf.png",
          title: "游戏换皮服务",
          info: "不想和别人一样？有自己的独特审美？价格优惠，都可以定制游戏换皮服务。",
          content: "全新设计  模仿产品",
          hidePopover: true,
          dialog: "gameChangeDialog",
        },
        {
          id: 2,
          image: "https://www.freeimg.cn/i/2024/08/13/66ba53591c750.png",
          title: "二次开发服务",
          info: "游戏功能满足不了现有需求？跟随需求，二次开发/修改更多功能。",
          content: "123test",
          hidePopover: true,
          dialog: "secondChangeDialog",
        },
        {
          id: 3,
          image: "https://www.freeimg.cn/i/2024/08/13/66ba535915189.png",
          title: "外包维护包月服务",
          info: "无需招人就可以轻松解决开发/BUG修复问题，省时省心省力，还省下了高昂的技术人力成本和场地成本。",
          url: "https://www.baidu.com",
          content: "123test",
          hidePopover: true,
          dialog: "fixedDialog",
        },
        {
          id: 4,
          image: "https://www.freeimg.cn/i/2024/08/13/66ba53591cc3f.png",
          title: "个性化定制服务",
          info: "前面满足不了需求，与商务洽谈，制定更多个性化的服务。",
          url: "https://www.baidu.com",
          content: "123test",
          hidePopover: true,
          dialog: "customDialog",
        },
      ],
      options: [
        {
          value: "YonoGames",
          label: "YonoGames",
          img: require("@/assets/yonodoc/ynlogo.png"),
        },
        {
          value: "CokeJogo",
          label: "CokeJogo",
          img: require("@/assets/yonodoc/cokelogo.jpg"),
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      imageUrl: "", // 用于显示base64图片
      base64Image: "", // 存储Base64格式图片
      uid: "",
    };
  },
  methods: {
    fixedGames() {
      if (
        (this.fixed.game,
        this.checkList,
        this.fixed.contact,
        this.fixed.info,
        this.base64Image)
      ) {
        const loading = this.$loading({
          lock: true,
          text: "正在提交",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        fixedGame(
          this.fixed.game,
          JSON.stringify(this.checkList),
          this.fixed.contact,
          this.fixed.info,
          this.base64Image,
          this.uid
        )
          .then((res) => {
            if (res.data.code === 200) {
              this.$message.success("您的需求我们已知晓，稍后将会有商务联系您");
              this.fixedDialog = false;
              loading.close();
            } else {
              this.$message.error(res.data.msg);
              loading.close();
            }
          })
          .catch((err) => {
            this.$message.error(err.data.msg);
            loading.close();
          });
      } else {
        this.$message.error("请填写完整信息");
      }
    },
    gamechanges() {
      if (
        (this.change.game,
        this.change.type,
        this.change.contact,
        this.change.info,
        this.base64Image)
      ) {
        const loading = this.$loading({
          lock: true,
          text: "正在提交",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        gamechange(
          this.change.game,
          this.change.type,
          this.change.contact,
          this.change.info,
          this.base64Image,
          this.uid
        )
          .then((res) => {
            if (res.data.code === 200) {
              this.$message.success("您的需求我们已知晓，稍后将会有商务联系您");
              this.gameChangeDialog = false;
              loading.close();
            } else {
              this.$message.error(res.data.msg);
              loading.close();
            }
          })
          .catch((err) => {
            this.$message.error(err.data.msg);
            loading.close();
          });
      } else {
        this.$message.error("请填写完整信息");
      }
    },
    secondChanges() {
      if ((this.second.game, this.second.contact, this.second.info)) {
        const loading = this.$loading({
          lock: true,
          text: "正在提交",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        secondChange(
          this.second.game,
          this.second.contact,
          this.second.info,
          this.base64Image,
          this.uid
        )
          .then((res) => {
            if (res.data.code === 200) {
              this.$message.success("您的需求我们已知晓，稍后将会有商务联系您");
              this.secondChangeDialog = false;
              loading.close();
            } else {
              this.$message.error(res.data.msg);
              loading.close();
            }
          })
          .catch((err) => {
            this.$message.error(err.data.msg);
            loading.close();
          });
      } else {
        this.$message.error("请填写完整信息");
      }
    },
    handleClick(dialogName) {
      console.log("传入的弹窗名称为" + dialogName);
      console.log(this[dialogName]); // 确认对象中的值
      this.$set(this, dialogName, true);
    },
    // 自定义上传行为，不实际上传，先进行Base64处理
    handleAvatarUpload(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // 将文件读取为Base64
      reader.onload = () => {
        this.imageUrl = reader.result; // 显示Base64图片
        this.base64Image = reader.result; // 存储Base64字符串以备上传
      };
      return false; // 阻止el-upload的默认上传行为
    },
  },
  created() {
    this.uid = localStorage.getItem("uid");
    console.log(this.uid);
    if (this.uid) {
      console.log("用户已登录");
    } else {
      console.log("用户未登录");
    }
  },
};
</script>

<style>
.buyBtn {
  font-size: 12px;
  color: #abb0bd;
  margin: 5px 0px;
  font-weight: bolder;
  border: 1px #abb0bd solid;
  padding: 5px 10px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.buyBtn:hover {
  background-color: #abb0bd;
  color: white;
}

.el-col-5 {
  width: 20%;
}

.el-col-5 > i {
  font-size: 40px;
  background-color: #ffa94f;
  color: white;
  padding: 20px;
  border-radius: 100px;
}

.el-col-5 > p {
  font-size: 13px;
}

.customTitle {
  color: #666666;
  padding: 10px 150px 0px 150px;
}

.customService {
  background-color: white;
  margin: 20px 150px 0 150px;
  padding: 15px;
  color: #666666;
}

.customInfo {
  color: #abb0bd;
  font-size: 12px;
  width: 150px;
  max-height: 3em;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.customList {
  background-color: white;
  padding: 20px;
  margin: 20px 150px 0 150px;
  text-align: center;
}

.phoneList {
  display: none;
}

@media screen and (max-width: 768px) {
  .customTitle {
    display: none;
  }
  .customService {
    margin: 0;
  }
  .customInfo {
    width: 100%;
  }
  .customList {
    margin: 0;
  }
  .hide {
    display: none;
  }
  .phoneList {
    display: contents;
  }
  .phoneListi {
    font-size: 40px;
    background-color: #ffa94f;
    color: white;
    padding: 20px;
    border-radius: 100px;
  }
}

.el-radio.is-bordered.is-checked {
  border-color: #cb1414 !important;
}

.el-textarea__inner:hover {
  border-color: #cb1414 !important;
}

.el-textarea__inner:focus {
  border-color: #cb1414 !important;
}

.el-range-editor.is-active,
.el-range-editor.is-active:hover,
.el-select .el-input.is-focus .el-input__inner {
  border-color: #cb1414 !important;
}

.el-select .el-input__inner:focus {
  border-color: #cb1414 !important;
}

.el-select-dropdown__item.selected {
  color: #cb1414;
  font-weight: 700;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #cb1414;
  transition: all 0.3s ease;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
